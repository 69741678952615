"use client"

import { type ComponentProps } from "react"

import { Toaster as Sonner } from "sonner"

import { useToastContext } from "@/contexts/ToastContext"
import useOnce from "@/hooks/useOnce"

type ToasterProps = ComponentProps<typeof Sonner>

const Toaster = ({ ...props }: ToasterProps) => {
  const { finishedLoading } = useToastContext()

  useOnce(finishedLoading)

  return (
    <Sonner
      theme="dark"
      className="toaster group"
      toastOptions={{
        style: {
          backgroundColor: "rgba(0, 0, 0, 0.9)",
        },
        classNames: {
          toast:
            "group toast text-white border-zinc-700 group-[.toaster]:shadow-lg",
          description: "group-[.toast]:text-muted-foreground",
          actionButton:
            "group-[.toast]:bg-emerald-200 group-[.toast]:text-secondary-foreground",
          cancelButton:
            "group-[.toast]:bg-muted group-[.toast]:text-muted-foreground",
        },
      }}
      {...props}
    />
  )
}

export { Toaster }
