"use client"

import { type FC } from "react"

import useOnce from "@/hooks/useOnce"
import useSuppressLogs from "@/hooks/useSuppressLogs"
import { initDayjs } from "@/utils/date"

import { setUserTimezoneCookie } from "./utils"

const MiscClientLogic: FC = () => {
  useSuppressLogs()

  useOnce(() => {
    setUserTimezoneCookie()
  })

  useOnce(() => {
    initDayjs()
  })

  return null
}

export default MiscClientLogic
