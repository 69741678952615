import { create } from "zustand"

import { type AuthToken, type UserData } from "@/types/User"

interface UserStore {
  userData: UserData | null
  authToken: AuthToken | null
  setUserData: (userData: UserData) => void
  setAuthToken: (authToken: AuthToken) => void
  setUserStore: (userData: UserData, authToken: AuthToken) => void
  clearUserStore: () => void
}

export const useUserStore = create<UserStore>((set) => ({
  userData: null,
  authToken: null,

  setUserData: (userData: UserData) => set(() => ({ userData })),
  setAuthToken: (authToken: AuthToken) => set(() => ({ authToken })),
  setUserStore: (userData: UserData, authToken: AuthToken) =>
    set({ userData, authToken }),
  clearUserStore: () => set({ userData: null, authToken: null }),
}))
