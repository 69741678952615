"use client"

import { type FC } from "react"

import { SessionProvider } from "next-auth/react"

import { type NextAuthProviderProps } from "./types"

const NextAuthProvider: FC<NextAuthProviderProps> = ({ children }) => (
  <SessionProvider>{children}</SessionProvider>
)

export default NextAuthProvider
