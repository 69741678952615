"use client"

import {
  createContext,
  type FC,
  useState,
  useContext,
  useMemo,
  useCallback,
} from "react"

import { toast } from "sonner"

import useOnce from "@/hooks/useOnce"
import useUser from "@/hooks/useUser"
import { type APIPlanID } from "@/types/User"

import type { UserContextValue, UserContextProviderProps } from "./types"

import { getUserPlan } from "./serverActions"

const UserContext = createContext<UserContextValue | undefined>(undefined)

export const UserContextProvider: FC<UserContextProviderProps> = ({
  children,
}) => {
  const user = useUser()
  const [currentPlan, setCurrentPlan] = useState<APIPlanID | null>(null)

  const fetchUserPlan = useCallback(async () => {
    if (user) {
      try {
        const userPlan = await getUserPlan(user?.id_user)

        if (userPlan) {
          setCurrentPlan(userPlan)
        }
      } catch (error) {
        toast.error("Failed to fetch your current plan")
      }
    }
  }, [user])

  const value = useMemo(() => {
    const val: UserContextValue = {
      currentPlan,
      updatePlan: (plan) => setCurrentPlan(plan),
    }

    return val
  }, [currentPlan])

  useOnce(
    () => {
      fetchUserPlan()
    },
    undefined,
    user != null,
  )

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>
}

export const useUserContext = () => {
  const context = useContext(UserContext)

  if (!context) {
    throw new Error(
      "`useUserContext` must be used within a `UserContextProvider`.",
    )
  }

  return context
}
