import { useEffect } from "react"

const SUPPRESS_WARNINGS = [
  // `mode="wait"` is the correct `mode` for SidebarItems. The other `mode`s don't produce the expected animation:
  `You're attempting to animate multiple children within AnimatePresence, but its mode is set to "wait". This will lead to odd visual behaviour.`,
]

const originalWarn = console.warn

/**
 * Suppresses specific `console` logs which are irrelevant
 */
const useSuppressLogs = () => {
  useEffect(() => {
    console.warn = (message: string, ...args: unknown[]) => {
      let suppressWarning = false

      SUPPRESS_WARNINGS.forEach((warning) => {
        if (message.includes(warning) || args.includes(warning)) {
          suppressWarning = true
        }
      })

      if (!suppressWarning) {
        originalWarn(message, ...args)
      }
    }

    return () => {
      console.warn = originalWarn
    }
  }, [])
}

export default useSuppressLogs
