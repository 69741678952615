"use client"

import { type FC, useCallback } from "react"

import { useSession } from "next-auth/react"

import useOnce from "@/hooks/useOnce"
import { useUserStore } from "@/stores/UserStore"
import { type UserData } from "@/types/User"

/**
 * Set `userData` to `user` state from session
 * TODO: remove this once SSR is done, we won't need this state anymore
 */
const LoadUserData: FC = () => {
  const session = useSession()
  const setUserData = useUserStore((s) => s.setUserData)

  const updateUser = useCallback(() => {
    if (session.status === "authenticated") {
      const { authToken, ...rest } = session.data.user
      setUserData(rest as UserData)
    }
  }, [setUserData, session])

  useOnce(updateUser)

  return null
}

export default LoadUserData
