"use client"

import { type FC } from "react"

import { createSyncStoragePersister } from "@tanstack/query-sync-storage-persister"
import { QueryClient, QueryClientProvider } from "@tanstack/react-query"

import useOnce from "@/hooks/useOnce"
import { getDays } from "@/utils/date"

import { type ReactQueryProviderProps } from "./types"

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      gcTime: getDays(1),
    },
  },
})

const ReactQueryProvider: FC<ReactQueryProviderProps> = ({ children }) => {
  useOnce(() => {
    const persister = createSyncStoragePersister({
      key: "react-query",
      storage: window.localStorage,
    })

    // persistQueryClientRestore({
    //   queryClient,
    //   persister,
    // })
    // persistQueryClient({
    //   queryClient,
    //   persister,
    // })
  })

  return (
    <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
  )
}

export default ReactQueryProvider
