"use client"

import React, { useEffect } from "react"

import dayjs from "dayjs"
import { ThemeProvider as NextThemesProvider } from "next-themes"
import { type ThemeProviderProps } from "next-themes/dist/types"

const ThemeProvider = ({ children, ...props }: ThemeProviderProps) => {
  useEffect(() => {
    dayjs.locale(navigator != null ? navigator.language : "en-US")
  }, [])

  return <NextThemesProvider {...props}>{children}</NextThemesProvider>
}

export default ThemeProvider
